import $ from 'cash-dom';

const documentLinks = {
  '0': '/wp-content/uploads/2024/04/oznamenie_PU_zodpovednost_povolanie_02_2025_f.pdf',
  '1': '/wp-content/uploads/2024/04/oznamenie_PU_zodpovednost_za_skodu_02_2025_f.pdf',
};

export const nahlasenieZodpovednostiZaSkoduFormComponent = async () => {
  $('#form-nahlasenie-zodpovednosti-za-skodu').each((n, el) => {
    const $el = $(el);
    console.log($el);

    const $radiosType = $el.find('.input-radio-type');
    const $radiosInsuredType = $el.find('.input-radio-insured-type');
    const $radiosInsuranceType = $el.find('.input-radio-insurance-type');
    const $documentLink = $el.find('.insurance-document-link');

    $radiosType.eq(0).on('change', () => {
      $el.find('.section-hidden').removeClass('hidden');
      $el.find('.insured-type-title').text('Ste');
    });
    $radiosType.eq(1).on('change', () => {
      $el.find('.section-hidden').removeClass('hidden');
      $el.find('.insured-type-title').text('Ste');
    });
    $radiosType.eq(2).on('change', () => {
      $el.find('.section-hidden').removeClass('hidden');
      $el.find('.insured-type-title').text('Poistený je');
    });

    $radiosInsuredType.eq(0).on('change', () => {
      $el.find('#insured_name_title').text('Meno, priezvisko');
      $el.find('#insured_ico_container').addClass('hidden');
      $el.find('#insured_ico').removeAttr('required');
    });
    $radiosInsuredType.eq(1).on('change', () => {
      $el.find('#insured_name_title').text('Názov spoločnosti');
      $el.find('#insured_ico_container').removeClass('hidden');
      $el.find('#insured_ico').attr('required', 'required');
    });

    $radiosInsuranceType.on('change', (event) => {
      const $target = $(event.target);
      const value = parseInt($target.val());
      $documentLink.attr('href', documentLinks[value]);
    });
  });
};
