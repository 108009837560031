import $ from 'cash-dom';
import axios from 'axios';

export const productSignUpPopup = async () => {
	const $popupButton = $('.sign-up-button');
	const $popup = $('#sign-up-popup');
	const $form = $popup.find('#sign-up-form');
	const $closeButton = $popup.find('.close-button');

	$popupButton.on('click', () => {
		$popup.removeClass('hidden');
		$popup.addClass('flex');
	});

	$closeButton.on('click', () => {
		$popup.addClass('hidden');
		$popup.removeClass('flex');
	});

	$form.on('submit', async (e) => {
		e.preventDefault();
		const form = document.querySelector('#sign-up-form');
		const formHeading = document.querySelector('#sign-up-heading');
		const formSuccess = document.querySelector('#sign-up-success');
		const productName = form.dataset.product;

		const data = {
			'product': productName,
			'firstName': $form.find('#first-name').val(),
			'lastName': $form.find('#last-name').val(),
			'postalCode': $form.find('#postal-code').val(),
			'phone': $form.find('#phone').val(),
			'email': $form.find('#email').val(),
			'agreeMarketing': $form.find('#agree-marketing').is(':checked'),
			'agreeContest': $form.find('#agree-contest').is(':checked'),
			'agreeGdpr': $form.find('#agree-gdpr').is(':checked'),
		};

		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			event: productName
		});

		axios.post('https://wkomplex-leads.thread.sk/web/lead/', data)
			.then(function (response) {
				console.log(response);
				form.classList.add('hidden');
				formHeading.classList.add('hidden');
				formSuccess.style.display = "flex";
			})
			.catch(function (error) {
				console.log(error);
			});
	});
};
