import $ from 'cash-dom';

export const insureHouseholdComponent = async () => {
  	const $root = $('#insure-household');

  	$root.find('.dropdown-trigger').each((n, el) => {
		const $trigger = $(el);
		const $previousSibling = $trigger.prev();

		$trigger.on('click', () => {
			if ($trigger.data('hidden') === true) {
				$previousSibling.removeClass('h-22').addClass('h-auto');
				$previousSibling.find('div').addClass('hidden');
				$trigger.data('hidden', false);
				$trigger.find('p').html('Zobraziť menej');
				$trigger.find('svg').addClass('rotate-180');
			}
			else{
				$previousSibling.addClass('h-22').removeClass('h-auto');
				$previousSibling.find('div').removeClass('hidden');
				$trigger.data('hidden', true);
				$trigger.find('p').html('Zobraziť všetky');
				$trigger.find('svg').removeClass('rotate-180');
			}
		});
	});
};
